import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"

// PROD
const firebaseConfig = {
  apiKey: "AIzaSyD0W7jojrMjFzEFIuta_FAoZtsbo7xnMuE",
  authDomain: "timetoliv-302c0.firebaseapp.com",
  projectId: "timetoliv-302c0",
  storageBucket: "timetoliv-302c0.appspot.com",
  messagingSenderId: "507555916305",
  appId: "1:507555916305:web:206f04220ed53634d25fb0"
}

// DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyCZ5WGBCdkGnjQ0osxyYYrgfddlsMLZf00",
//   authDomain: "timetoliv-dev.firebaseapp.com",
//   projectId: "timetoliv-dev",
//   storageBucket: "timetoliv-dev.appspot.com",
//   messagingSenderId: "47260761479",
//   appId: "1:47260761479:web:3e3e763cc8206a26fc2722"
// }

// > Init firebase
firebase.initializeApp(firebaseConfig)

// > Init service
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectAuthProvider = firebase.auth
const projectStorage = firebase.storage()

// const batch = projectFirestore.batch()

// > Timestamp
const timestamp = firebase.firestore.Timestamp

export {
  projectFirestore,
  projectAuth,
  timestamp,
  projectStorage,
  projectAuthProvider
}
