/* eslint-disable implicit-arrow-linebreak */
// ** React Imports
import { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import "babel-polyfill"
import "core-js/es/map"
import "core-js/es/set"

// ** Redux Imports
import { Provider } from "react-redux"
import { store } from "./redux/store"

// ** Intl & ThemeColors Context
import ability from "./configs/acl/ability"
import { AbilityContext } from "./utility/context/Can"
import { AuthContextProvider } from "./utility/context/AuthContext"
import { ToastContainer } from "react-toastify"
import { ThemeContext } from "./utility/context/ThemeColors"

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx.min"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"

import moment from "moment"
import "moment/locale/fr"
// const locale = window.navigator.userLanguage || window.navigator.language
moment.locale("fr")

// ** Service Worker
import * as serviceWorker from "./serviceWorker"

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))

ReactDOM.render(
  <Provider store={store}>
    <AuthContextProvider>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <LazyApp />
            <ToastContainer newestOnTop />
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </AuthContextProvider>
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
